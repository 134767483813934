<template>
    <div>
        <Header />
        <broadcamb />
        <privacy />
    </div>

</template>

<script>


import Header from '../components/layouts/Header.vue'
import broadcamb from '../components/layouts/broadCambPart.vue'
import privacy from '../components/privacy/privacyComponent.vue'
export default {
    name: 'PrivacyPage',
    components: {

        Header,
        broadcamb,
        privacy
    },
}
</script>

<style scoped></style>