<template>
    <section class="privacy-policy">
        <div class="privacy-container">
            <h1>Privacy Policy</h1>
            <p>This privacy policy applies to the Mass Marketing App app (hereby referred to as "Application") for
                mobile devices that was created by SM sarkar &amp; sons (hereby referred to as "Service Provider") as a
                Free service. This service is intended for use "AS IS".</p>

            <h2>Information Collection and Use</h2>
            <p>The Application collects information when you download and use it. This information may include
                information such as:</p>
            <ul>
                <li>Your device's Internet Protocol address (e.g. IP address)</li>
                <li>The pages of the Application that you visit, the time and date of your visit, the time spent on
                    those pages</li>
                <li>The time spent on the Application</li>
                <li>The operating system you use on your mobile device</li>
            </ul>

            <div class="location-info">
                <p>The Application collects your device's location, which helps the Service Provider determine your
                    approximate geographical location and make use of in below ways:</p>
                <ul>
                    <li>Geolocation Services: The Service Provider utilizes location data to provide features such as
                        personalized content, relevant recommendations, and location-based services.</li>
                    <li>Analytics and Improvements: Aggregated and anonymized location data helps the Service Provider
                        to analyze user behavior, identify trends, and improve the overall performance and functionality
                        of the Application.</li>
                    <li>Third-Party Services: Periodically, the Service Provider may transmit anonymized location data
                        to external services. These services assist them in enhancing the Application and optimizing
                        their offerings.</li>
                </ul>
            </div>

            <!-- Rest of the privacy policy content -->
            <p class="contact-info">
                If you have any questions regarding privacy while using the Application, or have questions about the
                practices, please contact the Service Provider via email at
                <a href="mailto:tech.wemasss@gmail.com">tech.wemasss@gmail.com</a>
            </p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'PrivacyComponent'
}
</script>

<style scoped>
.privacy-policy {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.6;
}

.privacy-container {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1.5rem;
}

h2 {
    font-size: 1.5rem;
    color: #444;
    margin: 1.5rem 0 1rem;
}

p {
    margin-bottom: 1rem;
    color: #666;
}

ul {
    margin: 1rem 0;
    padding-left: 2rem;
}

li {
    margin-bottom: 0.5rem;
    color: #666;
}

.location-info {
    background: #f8f9fa;
    padding: 1.5rem;
    border-radius: 6px;
    margin: 1.5rem 0;
}

.contact-info {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
}

a {
    color: #007bff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
</style>
